const toRadian = (degree: number): number => {
    return degree * Math.PI / 180;
}

export const getDistanceInKm = (latitude1: number, longitude1: number, latitude2: number, longitude2: number): number => {
    const earthRadius = 6371;
    // console.log(latitude1, longitude1, latitude2, longitude2);

    const deltaLatitude = toRadian(latitude2 - latitude1);
    const deltaLongitude = toRadian(longitude2 - longitude1);

    const a =
        Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
        Math.cos(toRadian(latitude1)) * Math.cos(toRadian(latitude2)) *
        Math.sin(deltaLongitude / 2) * Math.sin(deltaLongitude / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c;
}

const floatToBinary = (num: number, precision: number) => {
    let binary = '';
    while(precision) {
        num = num * 2;
        binary += (num >= 1 ? '1' : '0');
        num = (num >= 1 ? num - 1 : num);
        precision -= 1;
    }
    return binary;
}

export const mortonEncode = (lat: number, lon: number, precision = 23) => {  // 23 is chosen due to JavaScript's number precision limit.
    // Normalize to [0, 1]
    const normalizedLat = (lat + 90) / 180;
    const normalizedLon = (lon + 180) / 360;

    // Convert to binary
    const latBin = floatToBinary(normalizedLat, precision);
    const lonBin = floatToBinary(normalizedLon, precision);

    let interleavedBin = '';

    // Interleave the binary representations
    for (let i = 0; i < precision; i++) {
        interleavedBin += lonBin[i] ?? '';
        interleavedBin += latBin[i] ?? '';
    }

    // Convert the binary string back to decimal
    return parseInt(interleavedBin, 2);
}

export const setCookie = (name: string, value: any, daysToExpire: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (daysToExpire*24*60*60*1000));
    const expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const getCookie = (name: string) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2){
        // @ts-ignore
        return parts.pop().split(";").shift();
    }
}

export const isMobileBrowser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}