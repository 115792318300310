import React, {useEffect, useState} from "react";
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter, TESelect,
} from "tw-elements-react";
import {useTranslation} from "react-i18next";
import {useStoresCount} from "../utils/storesIDB/indexeddb-hooks";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {SelectData} from "tw-elements-react/dist/types/forms/Select/types";
import {setLanguage} from "../app/appStateSlice";

export default function SettingsDialog({visible, setVisible}: {visible: boolean, setVisible: (visible: boolean) => void}) {

    const { t, i18n } = useTranslation("settings");
    const storesCount = useStoresCount();

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [lastUpdated, setLastUpdated] = useState("");

    const appState = useAppSelector((state) => state.appState);
    const dispatch = useAppDispatch();

    const [langData, setLangData] = useState<SelectData[]>([]);
    const [currentLanguage, setCurrentLanguage] = useState<string>("en-US");

    useEffect(() => {
        setCurrentLanguage(appState.language);
    }, [appState]);

    useEffect(() => {
        setLangData([
            { text: "Hrvatski", value: "hr-HR", icon: "/img/flags/hr.svg", defaultSelected: appState.language === "hr-HR" },
            { text: "English", value: "en-UK", icon: "/img/flags/uk.svg", defaultSelected: appState.language === "en-UK" },
            { text: "Українська", value: "uk-UA", icon: "/img/flags/ua.svg", defaultSelected: appState.language === "uk-UA" },
        ])
    }, [currentLanguage]);



    useEffect(() => {
        const lu = parseInt(localStorage.getItem("lastUpdate") || "0");
        const d = new Date(lu).toLocaleString("uk-UA");
        setLastUpdated(d);

    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');

        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        }

        const handleResize = () => {
            if (mediaQuery.matches) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        mediaQuery.addEventListener('change', handleResize);

        // cleanup function
        return () => mediaQuery.removeEventListener('change', handleResize);
    }, []);

    const handleLanguageChange = (ev: SelectData | SelectData[]) => {
        if (Array.isArray(ev)){
            setCurrentLanguage(ev[0].value as string);
        } else {
            setCurrentLanguage(ev.value as string);
        }
    }

    const handleSave = () => {
        // @ts-ignore
        dispatch(setLanguage(currentLanguage));
        localStorage.setItem("lang", currentLanguage);
        // localStorage.setItem("i18nextLng", currentLanguage);
        i18n.changeLanguage(currentLanguage);
        setVisible(false);
    };

    return (
        <TEModal show={visible} setShow={setVisible} scrollable className="h-full md:h-auto">
            <TEModalDialog size={isSmallScreen ? "fullscreen": "lg"} >
                <TEModalContent>
                    <TEModalHeader>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                            {t("Settings")}
                        </h5>
                        <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setVisible(false)}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>
                    <TEModalBody>
                        <div className="w-full ">

                            <form action="#" className="flex flex-col w-full  items-center">
                                {/*<div className="relative mb-3 md:w-96 pt-5">*/}
                                <div className="flex flex-row justify-around gap-3 items-baseline">

                                    <p>{t("Language")}:</p>
                                    <TESelect data={langData} onValueChange={handleLanguageChange}>
                                    </TESelect>
                                </div>
                            </form>
                            <p className="p-3">
                                {t("description", {date: lastUpdated, count: storesCount})}
                            </p>
                        </div>
                    </TEModalBody>
                    <TEModalFooter>
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={() => setVisible(false)}
                            >
                                {t("Cancel")}
                            </button>
                        </TERipple>
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                onClick={handleSave}
                                className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            >
                                {t("Save")}
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    )
}