import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type AppMode = "all" | "favorite" | "search";
type AppLang = "en-UK" | "hr-HR" | "uk-UA";

interface AppState {
    center: {
        latitude: number;
        longitude: number;
    };
    currentMode: AppMode;
    language: AppLang;
}

const initialState: AppState = {
    center: {
        latitude: 0,
        longitude: 0,
    },
    currentMode: "all",
    language: "en-UK"
}

export const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setCenter: (state, action: PayloadAction<{latitude: number, longitude: number}>) => {
            state.center = action.payload;
        },
        setMode: (state, action: PayloadAction<AppMode>) => {
            state.currentMode = action.payload;
        },
        setLanguage: (state, action: PayloadAction<AppLang>) => {
            state.language = action.payload
        }
    }

})

export const {setCenter, setMode, setLanguage} = appStateSlice.actions;
export default appStateSlice.reducer;