import React, {useCallback, useEffect, useState} from "react";
import {Store} from "../types";
import {isMobileBrowser} from "../utils/helper";
import {MapIcon} from "@heroicons/react/24/outline";
import {Popup} from "react-map-gl";
import {useTranslation} from "react-i18next";
import {TECollapse} from "tw-elements-react";

export default function PopupMapModal({currentStore}: {currentStore: Store}) {

    const { t, i18n } = useTranslation("main");
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    const getTodayWorkDay = useCallback((store: Store) => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const key = days[(new Date()).getDay()];
        const workingHours = (store.working_hours as any)[key];
        if (workingHours)
            return workingHours.join(" - ");
        else
            return t("closed");
    }, []);
    return (
        <Popup
            closeButton={false}
            className="rounded-3xl p-6"
            maxWidth="400px"
            longitude={currentStore.longitude}
            latitude={currentStore.latitude}
        >
            <div
                className="f-full flex flex-row gap-3 content-baseline font-body text-neutral-800 dark:text-neutral-50">
                <img className="w-14 h-14 rounded" src={`/img/stores/${currentStore.store_name}.png`}
                     alt=""/>
                <div className="w-full flex flex-col gap-3">
                    <h4 className="font-semibold text-lg">{currentStore.name}</h4>
                    <div className="flex flex-row gap-1 items-center justify-end">
                        <span className="text=md " onClick={() => toggleShow()}>
                            {t("Today")}: {
                                getTodayWorkDay(currentStore)
                            }
                        </span>
                        <button
                            onClick={() => {
                                window.open(`https://www.google.com/maps/dir/?api=1&destination=${currentStore.latitude},${currentStore.longitude}`, isMobileBrowser() ? "_self" : "_blank")
                            }}
                            type="button"
                            className="inline-block rounded p-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                        >
                            <MapIcon className="w-4 h-4"/>
                        </button>
                    </div>
                    {/*<TECollapse show={show}>*/}
                    {/*    <div>*/}
                    {/*        <h1>aaaa</h1>*/}
                    {/*    </div>*/}
                    {/*</TECollapse>*/}
                </div>

            </div>
        </Popup>
    )
}