import React, {useState} from "react";
import {TECollapse} from "tw-elements-react";
import FilterPanel from "./FilterPanel";
import {useDebouncedCallback} from "use-debounce";
import {useTranslation} from "react-i18next";

export default function SearchInput({onSearch}: {onSearch: (value: string) => void}) {

    const { t } = useTranslation("search");

    const [show, setShow] = useState(false);

    const toggleShow = () => setShow(!show);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        onSearch(value);
    }, 500)

    return (
        <>
        <div className="relative w-full flex flex-row">
            <span className="absolute left-2 top-3 cursor-pointer" onClick={toggleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                     className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"/>
                </svg>

            </span>
            <input type="search" placeholder={t("Search") + "..."}
                   onChange={(e) => handleSearchChange(e.target.value)}
                   className="w-full pt-3 pr-3 pb-3 pl-9 rounded-2xl border-b-2 focus:border-b-2 focus:outline-0 "/>
        </div>
            <TECollapse show={show}>
                <FilterPanel />
            </TECollapse>
        </>
    )
}