import { configureStore } from '@reduxjs/toolkit'
import filterReducer from './filterSlice'
import locationReducer from "./myLocationSlice";
import favoritesReducer from "./favoriteSlice";
import appStateReducer from "./appStateSlice";

const store = configureStore({
    reducer: {
        filter: filterReducer,
        myLocation: locationReducer,
        favorites: favoritesReducer,
        appState: appStateReducer,
    },
})
export default store;

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

