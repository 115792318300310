import React from "react";
import { useAppSelector, useAppDispatch } from '../app/hooks'
import {filterValues} from "../utils/filter-values";
import clsx from "clsx";
import {FilterValue, toggle} from "../app/filterSlice";
import {useTranslation} from "react-i18next";

export default function FilterPanel(){
    const filters = useAppSelector((state) => state.filter);
    const dispatch = useAppDispatch();

    const { t, i18n } = useTranslation("filter");

    return (
        <div className="block rounded-lg bg-white p-3 shadow-lg dark:bg-neutral-700 dark:text-neutral-50">
            <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">Filters</h5>
            <div className="flex flex-wrap gap-3 ">

                {
                    Object.entries(filterValues).map(([key, value]) => (
                        <button
                            key={key}
                            type="button"
                            onClick={() => {dispatch(toggle(key))}}
                            disabled={value.disabled === true}
                            className={clsx(
                                "font-medium focus:outline-none duration-150 px-3 p-1 leading-normal transition text-xs ease-in-out uppercase focus:ring-0 inline-block rounded-full",
                                {
                                    "dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 bg-primary shadow-[0_4px_9px_-4px_#3b71ca] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] text-white dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)]": filters.filters.includes(key as FilterValue),
                                    "text-primary focus:text-primary-600 active:border-primary-700 hover:bg-opacity-10 dark:hover:bg-neutral-100 border-primary focus:border-primary-600 hover:text-primary-600 hover:bg-neutral-500 dark:hover:bg-opacity-10 active:text-primary-700 hover:border-primary-600 border-2": !filters.filters.includes(key as FilterValue),
                                    "bg-gray-400": value.disabled === true
                                }
                            )}>
                            {t(value.text)}
                        </button>
                    ))
                }


            </div>
        </div>
    )
}