export const storeObjectStoreOptions: IDBObjectStoreParameters = {
    keyPath: "store_id"
}

export type IndexesTypes = {
    name: string,
    keyPath: string | string[],
    options?: IDBIndexParameters
}

export const dbVersion = 1;

export const storeIndexes: IndexesTypes[] = [
    {
        name: "store_type",
        keyPath: "store_type",
        options: {
            unique: false
        }
    },
    {
        name: "geohash",
        keyPath: "geohash",
        options: {
            unique: false
        }
    },
    {
        name: "search_indexes",
        keyPath: "search_indexes",
        options: {
            unique: false,
            multiEntry: true
        }
    },
    {
        name: "working_monday",
        keyPath: "working_hours.monday",
        options: {
            unique: false,
        }
    },
    {
        name: "working_tuesday",
        keyPath: "working_hours.tuesday",
        options: {
            unique: false
        }
    },
    {
        name: "working_wednesday",
        keyPath: "working_hours.wednesday",
        options: {
            unique: false
        }
    },
    {
        name: "working_thursday",
        keyPath: "working_hours.thursday",
        options: {
            unique: false
        }
    },
    {
        name: "working_friday",
        keyPath: "working_hours.friday",
        options: {
            unique: false
        }
    },
    {
        name: "working_saturday",
        keyPath: "working_hours.saturday",
        options: {
            unique: false
        }
    },
    {
        name: "working_sunday",
        keyPath: "working_hours.sunday",
        options: {
            unique: false
        }
    }
]