import React from 'react';
import MainScreen from "./components/MainScreen";
import "./App.css"

function App() {

    return (
        <MainScreen />
    )
}

export default App;
