import React, {useEffect} from "react";
import StoreCard from "./StoreCard";
import SearchInput from "./SearchInput";
import {Store} from "../types";
import NavigationBlock from "./NavigationBlock";
import {useAppDispatch} from "../app/hooks";
import {setFavorites} from "../app/favoriteSlice";

export default function NavPanel({stores, onClick, onSearch}: {stores: Store[], onClick?: (id: string) => void, onSearch: (value: string) => void}) {


    const dispatch = useAppDispatch();

    // const [likedItems, setLikedItems] = useState<Array<{id: string}>>([]);
    //
    useEffect(() => {
        const favorites = JSON.parse(localStorage.getItem("favorites") || "[]");
        dispatch(setFavorites(favorites))
    }, [dispatch]);

    return (
        <div className="absolute left-0 top-0 max-h-screen w-96 hidden lg:block">

            <div className="rounded-2xl bg-white dark:bg-gray-800 w-full h-auto h-screen-margin m-3 border-2 overflow-scroll">
                <SearchInput onSearch={onSearch}/>

                <div className="flex flex-col gap-3 p-3 rounded-2xl ">
                    <NavigationBlock />
                    {
                        stores.map((store: Store) => (
                            <StoreCard store={store} key={store.store_id} onClick={onClick} />
                        ))
                    }
                </div>

            </div>
        </div>
    );
}