import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type FilterValue = "opennow" | "opensunday" | "opentomorrow" | "grocery" | "clothing" | "sport" | "furniture" | "restaurant" | "moll"

interface FilterSlice {
    filters: FilterValue[]
}

const initialState: FilterSlice = {
    filters: []
}

export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        toggle: (state, action: PayloadAction<string>) => {
          state.filters.includes(action.payload as FilterValue) ? state.filters = state.filters.filter((item) => {return item !== action.payload}) : state.filters.push(action.payload as FilterValue)
        }
    }
});

export  const  {toggle} = filterSlice.actions;
export default filterSlice.reducer;
