import {GeolocateControl, Map, NavigationControl, Popup, useControl} from "react-map-gl";
import NavPanel from "./NavPanel";
import NavPanelMobile from "./NavPanelMobile";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Store, StoreQuery, StoreQuerySet} from "../types";
import {filterValues} from "../utils/filter-values";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setLanguage, setMode} from "../app/appStateSlice";
import {getCookie, getDistanceInKm, isMobileBrowser, mortonEncode} from "../utils/helper";
import {setLocation} from "../app/myLocationSlice";
import "mapbox-gl/dist/mapbox-gl.css"
import {DeckProps, MapViewState, PickingInfo} from '@deck.gl/core';
import {IconLayer} from '@deck.gl/layers';
import {MapboxOverlay} from '@deck.gl/mapbox';
import {MapIcon} from "@heroicons/react/24/outline";
import {Map as MapboxMap} from "mapbox-gl"
import {useDebouncedCallback} from "use-debounce";
import {useStores2} from "../utils/storesIDB/indexeddb-hooks";
import { useTranslation } from 'react-i18next';
import TestingDialog from "./TestingDialog";
import PopupMapModal from "./PopupMapModal";

function DeckGLOverlay(props: DeckProps & {interleaved: boolean}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

export default function MainScreen(){

    const INITIAL_VIEW_STATE: MapViewState = {
        latitude: 45.3364182,
        longitude: 14.4608849,
        zoom: 13
    };

    const { t, i18n } = useTranslation("main");

    const [testingAccepted, setTestingAccepted] = useState(false);

    useEffect(() => {
        if (getCookie("test-accepted")){
            setTestingAccepted(true);
        }
    }, []);

    useEffect(() => {
        const setViewportHeight = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        window.addEventListener('resize', setViewportHeight);
        setViewportHeight();

        return () => window.removeEventListener('resize', setViewportHeight);
    }, []);



    // indexeddb
    const [dbQuery, setDbQuery] = useState<StoreQuery>({});
    const {records: dbStores, updateQuery} = useStores2({queries:[]});

    // redux
    const myLocation = useAppSelector((state) => state.myLocation);
    const filters = useAppSelector((state) => state.filter);
    const appState = useAppSelector((state) => state.appState);
    const favorites = useAppSelector(state => state.favorites)
    const dispatch = useAppDispatch();

    // map
    const [bounds, setBounds] = useState<number[] | null>(null);
    const [currentStore, setCurrentStore] = useState<Store | null>(null);

    const [stores, setStores] = useState<Store[]>([]);
    const [savedLocation, setSavedLocation] = useState<{latitude: number, longitude: number} | null>(null);

    const [needUpdate, setNeedUpdate] = useState<boolean>(false);

    const map = useRef<MapboxMap>(null);

    const updateLastLocation = useDebouncedCallback(({latitude, longitude}: {latitude: number, longitude: number}) => {
        localStorage.setItem("lastLocation", JSON.stringify({latitude, longitude}));
    }, 1000);

    useEffect(() => {
        const msgChannel = new MessageChannel();
        msgChannel.port1.onmessage = event => {
            if (event.data.error) {
                console.error(event.data.error);
            } else if (event.data.type === "updated") {
                localStorage.setItem("lastUpdate", Date.now().toString());
                setNeedUpdate(prevState => !prevState);
            } else {
                console.log("Received message from service worker: ", event.data);
            }
        };
        navigator.serviceWorker && navigator.serviceWorker.ready.then(registration => {
            const lastUpdate = localStorage.getItem("lastUpdate");
            if (lastUpdate === null || parseInt(lastUpdate) < (Date.now() - 1000 * 60 * 60 * 24) ) {
                registration.active?.postMessage({
                    type: "update"
                }, [msgChannel.port2]);

            }
        })
            .catch(console.log);
    }, []);


    useEffect(() => {
        const lastLocation = localStorage.getItem("lastLocation");
        if (lastLocation !== null)
            setSavedLocation(JSON.parse(lastLocation));

        const home = localStorage.getItem("home");
        if (home !== null){
            dispatch(setLocation(JSON.parse(home)));
        }

        const lang = localStorage.getItem("lang");
        if (lang !== null){
            // @ts-ignore
            dispatch(setLanguage(lang));
        }

    }, [dispatch]);

    useEffect(() => {
        if (savedLocation !== null) {
            map.current?.flyTo({center: {lat: savedLocation.latitude, lng: savedLocation.longitude}});
        }

    }, [map.current, savedLocation]);

    useEffect(() => {
        if (appState.currentMode === "favorite") {
            setStores(
                (dbStores as Store[]).filter((s) => favorites.ids.includes(s.store_id)).sort((a, b) => {
                    return getDistanceInKm(a.latitude, a.longitude, myLocation.latitude, myLocation.longitude) - getDistanceInKm(b.latitude, b.longitude, myLocation.latitude, myLocation.longitude);
                })
            )
        }
        if (["search", "all"].includes(appState.currentMode)) {
            setStores(
                (dbStores as Store[]).sort((a, b) => {
                    return getDistanceInKm(a.latitude, a.longitude, myLocation.latitude, myLocation.longitude) - getDistanceInKm(b.latitude, b.longitude, myLocation.latitude, myLocation.longitude);
                })
            )
        }

    }, [dbStores, myLocation, appState, favorites.ids]);

    useEffect(() => {
        const newQuery = dbQuery;
        const newQuerySet: StoreQuerySet = {
            queries: []
        }
        filters.filters.forEach((f) => {

            const fv = filterValues[f]

            const q = fv.query()
            if (fv.functions?.field !== undefined) {
                q.indexName = fv.functions.field();
            }
            if (fv.functions?.value !== undefined){
                q.query = [IDBKeyRange.only(fv.functions.value())]
            }
            newQuerySet.queries.push(q);

        });

        if (bounds !== null && appState.currentMode === "all"){
            const topLeftHash = mortonEncode(bounds[1], bounds[0])
            const bottomRightHash = mortonEncode(bounds[3], bounds[2])
            newQuery.indexName = "geohash";
            newQuery.query = [IDBKeyRange.bound(topLeftHash, bottomRightHash)];
            newQuerySet.queries.push(newQuery)
            updateQuery(newQuerySet);
        }
    }, [filters, bounds, appState.currentMode, needUpdate]);


    const layer = new IconLayer<Store>({
        id: 'IconLayer',
        data: stores,
        getColor: (d: Store) => {
            switch (d.store_name){
                case "spar":
                    return [0x0, 0x95, 0x40]
                case "studenac":
                    return [0xFF, 0x6C, 0x02]
                case "plodine":
                    return [0xE3, 0x06, 0x14]
                case "lidl":
                    return [0x06, 0x50, 0x7F]
                case "kaufland":
                    return [0xE3, 0x00, 0x10]
                case "eurospin":
                    return [0x00, 0x5E, 0xA8]
                case "tommy":
                    return [0xDB, 0x09, 0x35]
                case "konzum":
                    return [0xE8, 0x1C, 0x24]
                default:
                    return [140, 140, 0]
            }
        },
        // getIcon: (d: Store) => d.store_type,
        getIcon: (d: Store) => d.store_type,
        getPosition: (d: Store) => [d.longitude, d.latitude],
        getSize: 40,
        iconAtlas: '/img/icon-atlas.png',
        iconMapping: '/img/icon-atlas.json',
        pickable: false,

    });

    const handleSearch = useCallback((value: string) => {
        if (value.length === 0){
            dispatch(setMode("all"));
            const newQuery = dbQuery;
            if (bounds !== null && bounds !== undefined){
                const topLeftHash = mortonEncode(bounds[1], bounds[0])
                const bottomRightHash = mortonEncode(bounds[3], bounds[2])
                newQuery.indexName = "geohash";
                newQuery.query = [IDBKeyRange.bound(topLeftHash, bottomRightHash)];

            }

            updateQuery({queries: [newQuery]});
            return;
        }
        dispatch(setMode("search"));
        const newQuery = dbQuery;
        newQuery.indexName = "search_indexes";
        newQuery.query = value.toLowerCase().split(" ").map(v => IDBKeyRange.only(v));
        // updateQuery(newQuery);
        updateQuery({queries: [newQuery]});

    }, [dispatch, bounds, updateQuery]);

    const handleNavItemClick = useCallback((id: string) => {
        const store = stores.filter(s => s.store_id === id);
        if (store.length !== 0){
            map.current?.flyTo({zoom: 15, center: {lat: store[0].latitude, lng: store[0].longitude}});
            setCurrentStore(store[0]);

        }
    }, [stores]);

    const getTodayWorkDay = useCallback((store: Store) => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const key = days[(new Date()).getDay()];
        const workingHours = (store.working_hours as any)[key];
        if (workingHours)
            return workingHours.join(" - ");
        else
            return t("closed");
    }, []);

    const handleLocate = useCallback((e: { coords: { latitude: any; longitude: any; }; }) => {
        dispatch(setLocation({latitude: e.coords.latitude, longitude: e.coords.longitude}));
        localStorage.setItem("home", JSON.stringify({latitude: e.coords.latitude, longitude: e.coords.longitude}));
    }, [dispatch]);


    return (
        // <div className="w-screen h-screen">
        <div className="fullscreen-div w-screen">

                <Map
                    // @ts-ignore
                    ref={map}
                    initialViewState={INITIAL_VIEW_STATE}
                    onLoad={(e) => {
                        // @ts-ignore
                        if (e.target.__deck.viewManager == null) {
                            return;
                        }
                        // @ts-ignore
                        const bounds = e.target.__deck.viewManager._viewportMap.mapbox.getBounds();
                        setBounds(bounds);
                        setCurrentStore(null);
                    }}

                    onMoveEnd={(e) => {

                        // @ts-ignore
                        if (e.target.__deck.viewManager == null) {
                            return;
                        }
                        // @ts-ignore
                        const bounds = e.target.__deck.viewManager._viewportMap.mapbox.getBounds();
                        setBounds(bounds);
                        updateLastLocation({latitude: e.viewState.latitude, longitude: e.viewState.longitude});
                        // setCurrentStore(null);
                    }}
                    onClick={e => {
                        if (currentStore) {
                            setCurrentStore(null);
                            return;
                        }

                        const strs = stores
                            .filter(s => getDistanceInKm(s.latitude, s.longitude, e.lngLat.lat, e.lngLat.lng) < (.1 * 22/e.target.getZoom()))
                            .sort(s => getDistanceInKm(s.latitude, s.longitude, e.lngLat.lat, e.lngLat.lng));

                        if (strs.length > 0){
                            setCurrentStore(strs[0]);
                            map.current?.flyTo({center: {lat: strs[0].latitude, lng: strs[0].longitude}});
                        } else {
                            setCurrentStore(null);
                        }
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKE}
                >

                    <DeckGLOverlay layers={[layer]}  interleaved getTooltip={(info: PickingInfo<Store>) => info && (info.object?.name || null)}/>
                    <NavigationControl position="bottom-right" />
                    <GeolocateControl position="bottom-right" onGeolocate={handleLocate} />

                    {
                        currentStore !== null && (
                            <PopupMapModal currentStore={currentStore} />
                            // <Popup
                            //     closeButton={false}
                            //     className="rounded-3xl p-6"
                            //     maxWidth="300px"
                            //     longitude={currentStore.longitude}
                            //     latitude={currentStore.latitude}
                            // >
                            //     <div
                            //         className="f-full flex flex-row gap-3 content-baseline font-body text-neutral-800 dark:text-neutral-50">
                            //         <img className="w-14 h-14 rounded" src={`/img/stores/${currentStore.store_name}.png`}
                            //              alt=""/>
                            //         <div className="w-full flex flex-col gap-3">
                            //             <h4 className="font-semibold text-lg">{currentStore.name}</h4>
                            //             <div className="flex flex-row gap-1 items-center justify-end">
                            //                  <span className="text=md ">
                            //                      {t("Today")}: {
                            //                      getTodayWorkDay(currentStore)
                            //                  }
                            //                  </span>
                            //                 <button
                            //                     onClick={() => {
                            //                         window.open(`https://www.google.com/maps/dir/?api=1&destination=${currentStore.latitude},${currentStore.longitude}`, isMobileBrowser() ? "_self" : "_blank")
                            //                     }}
                            //                     type="button"
                            //                     className="inline-block rounded p-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            //                 >
                            //                     <MapIcon className="w-4 h-4"/>
                            //                 </button>
                            //             </div>
                            //         </div>
                            //
                            //     </div>
                            // </Popup>
                        )
                    }


                </Map>

            <NavPanel onSearch={handleSearch} stores={stores} onClick={handleNavItemClick}/>
            <NavPanelMobile onSearch={handleSearch} stores={stores} onClick={handleNavItemClick}/>
            {testingAccepted || (<TestingDialog visible={true} />)}

        </div>
    );
}