import {StoreQuery} from "../types";

type FilterValues = {
    [name: string]: {
        text: string,
        query: () => StoreQuery,
        filter?: (string | boolean | number)[],
        functions?: {
            field?: () => string
            value?: () => string | boolean | number
            // indexName?: () => string | boolean | number
        },
        disabled?: boolean
    }
}


const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const filterValues: FilterValues = {
    opennow: {
        text: "Open today",
        query: () => {
            return {
                query: [IDBKeyRange.lowerBound("")]
            }
        },
        // filter: ["", "==", true],
        functions: {
            field: () => {
                return `working_${days[(new Date()).getDay()]}`
            }
        }
    },
    opensunday: {
        text: "Open on Sunday",
        query: () => {
            return {
                indexName: "working_sunday",
                query: [IDBKeyRange.lowerBound("")]
            }
        } ,
        // filter: ["working_days.sunday", "==", true]
    },
    opentomorrow: {
        text: "Open tomorrow",
        query: () => {
            return {
                query: [IDBKeyRange.lowerBound("")]
            }
        } ,
        // filter: ["", "==", true],
        functions: {
            field: () => {
                const index = ((new Date()).getDay() + 1) % days.length;
                return `working_${days[index]}`
            }
        }
    },
    grocery: {
        text: "Grocery",
        query: () => {
            return {
                indexName: "store_type",
                query: [IDBKeyRange.only("grocery")],
            }
        },
        // filter: ["store_type", "==", "grocery"]
    },
    // clothing: {
    //     text: "Clothing",
    //     filter: ["store_type", "==", "clothing"],
    //     disabled: true
    // },
    // sport: {
    //     text: "Sport goods",
    //     filter: ["store_type", "==", "sport"],
    //     disabled: true
    // },
    // furniture: {
    //     text: "Furniture",
    //     filter: ["store_type", "==", "furniture"],
    //     disabled: true
    // },
    // restaurant: {
    //     text: "Restaurant",
    //     filter: ["store_type", "==", "restaurant"],
    //     disabled: true
    // },
    // moll: {
    //     text: "Moll",
    //     filter: ["store_type", "==", "moll"],
    //     disabled: true
    // }
}