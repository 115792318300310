import React from "react";
import {AdjustmentsVerticalIcon, ArrowLeftIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import NavigationBlock from "./NavigationBlock";
import FilterPanel from "./FilterPanel";
import {TECollapse} from "tw-elements-react";
import {Store} from "../types";
import StoreCard from "./StoreCard";
import {useDebouncedCallback} from "use-debounce";
import {useTranslation} from "react-i18next";

export default function NavPanelMobile({stores, onClick, onSearch}: {stores: Store[], onClick?: (id: string) => void, onSearch: (value: string) => void}) {

    const [isOpen, setIsOpen] = React.useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
    const { t } = useTranslation("search");

    const handleFiltersClick = () => {
        setIsFiltersOpen((isfOpen) => !isfOpen);
    };

    const handleOnClick = (id: string) => {
        setIsOpen(false);
        if (onClick)
            onClick(id);
    };

    const handleSearchChange = useDebouncedCallback((value: string) => {
        onSearch(value);
    }, 500)

    return (
        <div className="absolute left-0 top-0 w-full h-auto block lg:hidden items-center z-[1000]">

            {
                isOpen ? (
                    <div className="w-full flex flex-col gap-3 h-screen bg-white dark:bg-neutral-700 dark:text-neutral-50 p-3">
                        <div className="relative w-full flex flex-row items-center">
                            <span className="absolute left-1 top-1 cursor-pointer" onClick={() => {setIsOpen(false)}}>
                                <ArrowLeftIcon className="h-10 w-10 p-2 "/>
                            </span>
                            <span className="absolute right-2 top-1 cursor-pointer" onClick={handleFiltersClick}>
                                <AdjustmentsVerticalIcon className="h-10 w-10 p-2 "/>
                            </span>
                            <input type="text" placeholder={t("Search") + "..."} onChange={(e) =>handleSearchChange(e.target.value)}
                                   className="w-full pt-3 pb-3 pl-9 pr-9 rounded-2xl border-2 border-secondary focus:outline-0 "/>
                        </div>
                        <div className="h-auto w-full">
                            <TECollapse show={isFiltersOpen}>
                                <FilterPanel />
                            </TECollapse>
                        </div>

                        <NavigationBlock />
                        <div className="flex flex-col gap-3 p-3">
                            {
                                stores.map((store: Store) => (
                                    <StoreCard store={store} key={store.store_id} onClick={handleOnClick} />
                                ))
                            }
                        </div>
                    </div>
                ) : (
                    <div onClick={() => {
                        setIsOpen(true)
                    }}
                         className="lg:hidden m-3 xs:flex xs:flex-row  gap-2 text-secondary rounded-2xl bg-white dark:bg-neutral-700 dark:text-neutral-50 p-3">
                            <MagnifyingGlassIcon className="w-6 h-6" />
                        Search...
                    </div>
                )
            }

        </div>
    );
}