import {CogIcon, HeartIcon, HomeIcon} from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, {useCallback, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setMode} from "../app/appStateSlice";
import {useTranslation} from "react-i18next";
import SettingsDialog from "./SettingsDialog";

type NavLinks = {
    icon: React.ReactNode;
    label: string;
    link_id: string;
    handler: () => void,
    passive?: boolean
}



export default  function NavigationBlock(){

    const { t } = useTranslation("navigation");

    const dispatch = useAppDispatch();
    const appState = useAppSelector(state => state.appState);
    const navlinks: NavLinks[] = [
        {
            label: t("All"),
            icon: <HomeIcon className="h-6 w-6" />,
            handler: () => {
                dispatch(setMode("all"));
            },
            link_id: "all"
        },
        {
            label: t("Favorite"),
            icon: <HeartIcon className="h-6 w-6" />,
            link_id: "favorite",
            handler: () => {
                dispatch(setMode("favorite"));
            }
        },
        {
            label: t("Settings"),
            icon: <CogIcon className="h-6 w-6" />,
            handler: () => {
                setIsSettingsOpen(true);
            },
            link_id: "settings",
            passive: true
        }
    ];

    const [activeIndex, setActiveIndex] = React.useState(0);
    const[isSettingsOpen, setIsSettingsOpen] = React.useState(false);
    const handleSettingsOpen = useCallback((isOpen: boolean) => {
        setIsSettingsOpen(isOpen);
    }, []);

    useEffect(() => {
        if (appState.currentMode === "all") {
            setActiveIndex(0);
        }
        else if (appState.currentMode === "favorite") {
            setActiveIndex(1);
        } else {
            setActiveIndex(-1)
        }
        // setActiveIndex(appState.currentMode === "all" ? 0 : 1);
    }, [appState.currentMode]);

    const handleLinkClick = (index: number): void => {
        if (!navlinks[index].passive)
            setActiveIndex(index);
        navlinks[index].handler();
    }
    return (
        <div className="grid grid-cols-3 w-full text-sm font-body gap-1 h-auto">

            {

                navlinks.map((link, index) => (
                    <div key={index} className={clsx(
                        "w-full cursor-pointer rounded-lg hover:border-secondary-500 flex flex-col  h-24 border-2 items-center justify-center  hover:shadow-md ",
                        {
                            "border-primary-500": index === activeIndex,
                        }
                    )}
                         onClick={() => {handleLinkClick(index)}}
                    >
                        {link.icon}
                        <span>{link.label}</span>
                    </div>
                ))
            }

            <SettingsDialog visible={isSettingsOpen} setVisible={handleSettingsOpen} />

        </div>
    )
}