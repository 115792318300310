import {dbVersion, storeIndexes, storeObjectStoreOptions} from "./store-config";


export const getStore = (): Promise<IDBObjectStore> => {
    return new Promise((resolve, reject) => {
        let updateNeeded = false;
        const DBOpenRequest = indexedDB.open("Stores", dbVersion);
        DBOpenRequest.onsuccess = (ev) => {
            const db = DBOpenRequest.result;
            if (!updateNeeded){
                const tx = db.transaction("store", "readwrite");
                resolve(tx.objectStore("store"));
            }

        };
        DBOpenRequest.onerror = (ev) => {
            reject();
        };
        DBOpenRequest.onupgradeneeded = (ev) => {
            updateNeeded = true;
            // @ts-ignore
            const db: IDBDatabase = ev.target.result;
            const objectStore = db.createObjectStore("store", storeObjectStoreOptions);

            storeIndexes.forEach(index => {
                objectStore.createIndex(index.name, index.keyPath, index.options);
            });
            objectStore.transaction.oncomplete = (ev) => {
                // resolve(objectStore);
                const tx = db.transaction("store", "readwrite");
                resolve(tx.objectStore("store"));
            }
        };
    })
}