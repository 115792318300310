import {createSlice, PayloadAction} from '@reduxjs/toolkit'


interface Location {
    latitude: number,
    longitude: number,
}

const initialState: Location = {
    latitude: 0,
    longitude: 0
}

export const locationSlice = createSlice({
    name: "myLocation",
    initialState,
    reducers: {
        setLocation: (state, action: PayloadAction<{latitude: number, longitude: number}>) => {
            state.latitude = action.payload.latitude;
            state.longitude = action.payload.longitude;
        }
    }
})

export const {setLocation} = locationSlice.actions;
export default locationSlice.reducer;
