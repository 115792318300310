import React, {useEffect, useState} from "react";
import {getStore} from "./indexeddb";
import {Store, StoreQuery, StoreQuerySet} from "../../types";

export const useStoresCount = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        getStore()
            .then(objectStore => {
                const countRequest = objectStore.count();
                countRequest.onsuccess = () => {
                    setCount(countRequest.result)
                };
            })
    }, []);

    return count;
}

export const useStores2 = function<T = Store>(initQuery: StoreQuerySet) {
    const [querySet, setQuerySet] = useState<StoreQuerySet>(initQuery);
    const [records, setRecords] = useState<T[]>([]);

    const processQuery = (objectStore: IDBObjectStore, query: StoreQuery) => {
        return new Promise((resolve, reject) => {

            if (query.query !== undefined && query.query !== null){
                let promises = query.query.map(q => {
                    return new Promise((resolve, reject) => {
                        let request: IDBRequest;
                        if (query.indexName){
                            const index = objectStore.index(query.indexName);
                            request = index.getAll(q, query.count || undefined);

                        } else{
                            request = objectStore.getAll(q, query.count || undefined);
                        }
                        request.onsuccess = () => {
                            resolve(request.result as T[]);
                        }
                    });
                });
                Promise.all(promises)
                    .then(results => {
                        if (results.length === 0){
                            resolve([]);
                        }
                        let flatResults: T[]
                        if (query.operator === "or"){
                            // @ts-ignore
                            flatResults = [].concat(...results);

                        } else {
                            // @ts-ignore
                            flatResults = results.reduce((a, b) => a.filter(c => b.findIndex(el => c.store_id === el.store_id) !== -1));
                        }
                        resolve(flatResults);
                    });
            } else {
                let request: IDBRequest;
                if (query.indexName){
                    const index = objectStore.index(query.indexName);
                    request = index.getAll(query.query, query.count || undefined);

                } else{
                    request = objectStore.getAll(query.query, query.count || undefined);
                }
                request.onsuccess = () => {
                    resolve(request.result as T[]);
                }
            }

        });
    }

    useEffect(() => {

        getStore()
            .then(objectStore => {
                let promisees = querySet.queries.map(q => processQuery(objectStore, q));
                Promise.all(promisees)
                    .then(results => {
                        if (results.length === 0){
                            setRecords([]);
                            return;
                        }
                        let flatResults: T[]
                        if (querySet.operator === "or"){
                            // @ts-ignore
                            flatResults = [].concat(...results);

                        } else {
                            // @ts-ignore
                            flatResults = results.reduce((a, b) => a.filter(c => b.findIndex(el => c.store_id === el.store_id) !== -1));
                        }
                        setRecords(flatResults);
                    })
            })
    }, [querySet]);

    const updateQuery = (newQuerySet: StoreQuerySet) => {
        setQuerySet(newQuerySet);
    }

    return {records, updateQuery};

}


export const useStores = function<T = Store>(initQuery: StoreQuery) {

    const [indexName, setIndexName] = useState<string | null | undefined>(undefined);
    const [query, setQuery] = useState<IDBValidKey[] | IDBKeyRange[] | null | undefined>(undefined);
    const [count, setCount] = useState<number | null | undefined>(undefined);
    const [operator, setOperator] = useState<"and" | "or" | null | undefined>(undefined);

    const [records, setRecords] = useState<T[]>([]);

    const handleRequestSuccess = (request: IDBRequest<T[]>) => {
        request.onsuccess = (ev) => {
            setRecords(request.result as T[]);
        };
    }

    useEffect(() => {
        console.log("useStores init");
        setQuery(initQuery.query);
        setCount(initQuery.count);
        setIndexName(initQuery.indexName);
        setOperator(initQuery.operator || "and");
    }, []);

    useEffect(() => {

        console.log("useStores update");
        getStore()
            .then((objectStore) => {

                if (query !== undefined && query !== null){
                    let promises = query.map(q => {
                        return new Promise((resolve, reject) => {
                            let request: IDBRequest;
                            if (indexName){
                                const index = objectStore.index(indexName);
                                request = index.getAll(q, count || undefined);

                            } else{
                                request = objectStore.getAll(q, count || undefined);
                            }
                            request.onsuccess = () => {
                                resolve(request.result as T[]);
                            }
                        });
                    });
                    Promise.all(promises)
                        .then(results => {
                            let flatResults: T[]
                            if (operator === "or"){
                                // @ts-ignore
                                flatResults = [].concat(...results);

                            } else {
                                // @ts-ignore
                                flatResults = results.reduce((a, b) => a.filter(c => b.findIndex(el => c.store_id === el.store_id) !== -1));
                                console.log(flatResults)
                            }
                            setRecords(flatResults);
                        });
                } else {
                    let request: IDBRequest;
                    if (indexName){
                        const index = objectStore.index(indexName);
                        request = index.getAll(query, count || undefined);

                    } else{
                        request = objectStore.getAll(query, count || undefined);
                    }
                    request.onsuccess = () => {
                        setRecords(request.result as T[]);
                    }
                }
            })
            .catch(console.error);

    }, [query, count, indexName]);

    const updateQuery = (newQuery: StoreQuery) => {
        console.log("newQuery", newQuery);
        setQuery(newQuery.query);
        setCount(newQuery.count);
        setIndexName(newQuery.indexName);
    }

    return {records, updateQuery};
}
