import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface FavoritesSlice {
    ids: string[]
}

const initialState: FavoritesSlice = {
    ids: []
};

export const favoriteSlice = createSlice({
    name: "favorites",
    initialState,
    reducers:{
        addFavorite: (state, action: PayloadAction<string>) => {
            state.ids = [...state.ids, action.payload];
            // state = {...state, ids: [...state.ids, action.payload]};
        },
        removeFavorite: (state, action: PayloadAction<string>) => {
            state.ids = state.ids.filter(item => item !== action.payload);
            // state = {...state, ids: state.ids.filter(item => item !== action.payload)};
        },
        setFavorites: (state, action: PayloadAction<string[]>) => {
            state.ids = action.payload;
            // state = {...state, ids: action.payload};
        }
    }
})

export const {addFavorite, removeFavorite, setFavorites} = favoriteSlice.actions;
export default favoriteSlice.reducer;